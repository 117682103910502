import {InjectionToken} from '@angular/core'

export interface LightboxItem {
  src: string
}

export class LightboxData {
  constructor(public items: LightboxItem[],
              public initialIndex: number) {
  }
}

export const LIGHTBOX_DATA = new InjectionToken<LightboxData>('LIGHTBOX_DATA')
