import {LightboxItem} from '../../shared/models/lightbox.model'

export enum HomeDataType {
  ALBUM,
  ARTICLE
}

export interface ImageGridItem extends LightboxItem {
  src: string
  thumb: string
  name?: string
}

export class ImageItem implements ImageGridItem {
  constructor(public src: string,
              public thumb: string,
              public name?: string) {
  }
}

export class GalleryItem implements ImageGridItem {
  constructor(public key: string,
              public src: string,
              public thumb: string,
              public name: string) {
  }
}

export class HomeItem implements ImageGridItem {
  constructor(public type: HomeDataType,
              public key: string,
              public src: string,
              public thumb: string,
              public name: string) {
  }
}

export class ImageClickEvent<T extends ImageGridItem> {
  constructor(public item: T,
              public index: number) {
  }
}

export class ArticleItem {
  constructor(public key: string,
              public name: string,
              public abstract: string,
              public thumb?: string) {
  }
}

export interface AlbumDataModel {
  key: string,
  name: string,
  previewImg: string,
  images: Array<string>
}

export interface HomeDataModel {
  type: HomeDataType;
  key: string
}

export interface ArticleDataModel {
  key: string,
  name: string,
  abstract: string,
  albumKey?: string
}
