import {Injectable} from '@angular/core'
import {NavigationItem} from '../models/NavigationItem'
import {
  ARTICLES_NAME,
  ARTICLES_PATH,
  CLUB_NAME,
  CLUB_PATH,
  EVENTS_NAME,
  EVENTS_PATH,
  GALLERY_NAME,
  GALLERY_PATH,
  HOME_NAME,
  HOME_PATH
} from '../../shared/constants/route.constants'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  static readonly NAVIGATION_ITEMS: NavigationItem[] = [
    new NavigationItem(HOME_PATH, HOME_NAME),
    new NavigationItem(CLUB_PATH, CLUB_NAME),
    new NavigationItem(EVENTS_PATH, EVENTS_NAME),
    new NavigationItem(ARTICLES_PATH, ARTICLES_NAME),
    new NavigationItem(GALLERY_PATH, GALLERY_NAME)
  ]
}
