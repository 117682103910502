import {Injectable} from '@angular/core'
import {ArticleDataModel} from '../models/data.model'
import {UtilService} from './util.service'

@Injectable({
  providedIn: 'root'
})
export class ArticleDataService {
  getDataByKey(key: string): ArticleDataModel {
    return UtilService.ensureDefined(this.getData().find((it: ArticleDataModel) => it.key === key))
  }

  getData(): Array<ArticleDataModel> {
    return [
      {
        "key": "gv-2024",
        "name": "GV 2024",
        "abstract": "Am 13. März 2024 fand die GV im Restaurant Bijou in Bremgarten statt.",
        "albumKey": "gv-2024"
      },
      {
        "key": "vereinsmeisterschaft-2023",
        "name": "Vereinsmeisterschaft 2023",
        "abstract": "Der Treffpunkt in OL war auch dieses Jahr der Start zum Vereinsweekend. Heuer war Thomas F. unser Gastfischer – toll, dass sich jedes Jahr ein neuer Gast findet, der\n" +
          "            sich so gut in die Runde integriert. Nach der kurzen Begrüssung gings los via Volg in Richtung Innerschweiz. Unterwegs wurde noch der ÖV-Reisende aufgegriffen.",
        "albumKey": "fischerausflug-2023"
      },
      {
        "key": "gv-2023",
        "name": "GV 2023",
        "abstract": "Am 17. März 2023 fand die GV im Restaurant Waldheim in Hermetschwil-Staffeln statt.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2022",
        "name": "Vereinsmeisterschaft 2022",
        "abstract": "Aufgrund des frühen Termins der Vereinsmeisterschaft und mit der damit verbundenen geringen Anzahl an befischbaren Seen, fiel die Wahl auch dieses Jahr auf den Lungernsee.",
        "albumKey": "fischerausflug-2022"
      },
      {
        "key": "gv-2022",
        "name": "GV 2022",
        "abstract": "Am 18. März 2022 fand die GV im Restaurant Central in Oberlunkhofen statt.",
        "albumKey": undefined
      },
      {
        "key": "vereinsreise-2021",
        "name": "Vereinsreise 2021",
        "abstract": "Nachdem das letztjährige Jubiläum kurzfristig von einem mehrtägigen Auslandreisli in einen tollen One-Day-Trip verlagert wurde, wuchs bei den Mitgliedern der Wunsch so einen Vereinsausflug auch im 2021 durchzuführen.",
        "albumKey": "vereinsreise-2021"
      },
      {
        "key": "vereinsmeisterschaft-2021",
        "name": "Vereinsmeisterschaft 2021",
        "abstract": "Analog der Meisterschaft 2020 wurde auch die diesjährige Meisterschaft auf dem Lungernsee abgehalten. Ebenfalls wurde das Programm wiederum an einem Tag und ohne Gastfischer durchgeführt.",
        "albumKey": "fischerausflug-2021"
      },
      {
        "key": "15-jahr-jubilaeumsreise-2020",
        "name": "15 Jahr-Jubiläumsreise 2020",
        "abstract": "Ursprünglich war ein verlängertes Weekend in einer europäischen Hauptstadt geplant gewesen. Coronabedingt musste dieser Plan 2 Wochen vor Abflug bei Seite gelegt werden. Innert Kürze wurde eine neue Reise auf die Beine gestellt – nun sollte es für einen Tag durch den Kanton Aargau gehen.",
        "albumKey": "jubilaeumsreise-2020"
      },
      {
        "key": "vereinsmeisterschaft-2020",
        "name": "Vereinsmeisterschaft 2020",
        "abstract": "Die diesjährige Meisterschaft wurde Covid-19 bedingt schlanker durchgeführt. Anstelle der geplanten 2-tägigen Meisterschaft mitsamt einem Gast im Juni, wurde auf einen eintägigen Wettkampf ohne Gast umgeschwenkt und zwar auf dem Lungernsee.",
        "albumKey": "fischerausflug-2020"
      },
      {
        "key": "gv-2020",
        "name": "GV 2020",
        "abstract": "Am 6. März 2020 fand die GV im Bauernhof Oberlunkhofen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2019",
        "name": "Weihnachtessen 2019",
        "abstract": "Das Weihnachtsessen 2019 fand analog der GV 2019 im Restaurant Grüene Boden in Berikon statt. Die Mitglieder gaben sich wie stets vollzählig die Ehre. Bis auf ein Mitglied wurde auch die Vorschrift des Tragens der neuen Vereinskluft umgesetzt.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2019",
        "name": "Vereinsmeisterschaft 2019",
        "abstract": "Gleich zwei Rekorde auf dem Oberalppass!!!",
        "albumKey": "fischerausflug-2019"
      },
      {
        "key": "sommerplausch-2019",
        "name": "Sommerplausch 2019",
        "abstract": "Dieses Jahr zeichnete sich ein neues OK verantwortlich für die Durchführung des Sommerevents. Dies klappte hervorragend und ein grosses Dankeschön sei an dieser Stelle auch nochmals erwähnt.",
        "albumKey": "sommerplausch-2019"
      },
      {
        "key": "irland-2019",
        "name": "Nordirland 2019",
        "abstract": "Wie in der Vergangenheit verbrachten auch im 2019 Würmlitaucher-Jungs eine Fischerwoche auf dem Erne in Nordirland. Bei gutem Wetter wurden über 50 Hechte sowie mehrere Barsche erbeutet.",
        "albumKey": "irland-2019"
      },
      {
        "key": "gv-2019",
        "name": "GV 2019",
        "abstract": "Am 12. Februar 2019 fand die GV im Landgasthof Grüenebode in Berikon statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2018",
        "name": "Weihnachtessen 2018",
        "abstract": "Das diesjährige Weihnachtsessen fand im Restaurant Bahnhof in Mettmenstetten statt. Die Mitglieder gaben sich wie stets vollzählig die Ehre.",
      },
      {
        "key": "vereinsmeisterschaft-2018",
        "name": "Vereinsmeisterschaft 2018",
        "abstract": "Ein Novum: zwei Gewinner der Meisterschaft 2018 auf dem Oberalppass!!!",
        "albumKey": "fischerausflug-2018"
      },
      {
        "key": "sommerplausch-2018",
        "name": "Sommerplausch 2018",
        "abstract": "Analog zum letzten Jahr wurde für den diesjährigen Sommerplausch wiederum die Anlage Falter in Oberwil-Lieli als Durchführungsort bereits im Vorfeld gewählt.",
        "albumKey": "sommerplausch-2018"
      },
      {
        "key": "gv-2018",
        "name": "GV 2018",
        "abstract": "Am 8. Februar 2018 fand die GV im Landgasthof Grüenebode in Berikon statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2017",
        "name": "Weihnachtsessen 2017 mit viel Fleisch",
        "abstract": "Das diesjährige Weihnachtsessen fand analog dem Vorjahr im Restaurant Central Oberlunkhofen statt. Die Mitglieder gaben sich wie stets vollzählig die Ehre.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2017",
        "name": "Vereinsmeisterschaft 2017",
        "abstract": "Die Vereinsmeisterschaft auf der Melchsee-Frutt brachte dieses Jahr wenig Fisch aber einen glücklichen Gewinner.",
        "albumKey": "fischerausflug-2017"
      },
      {
        "key": "sommerplausch-2017",
        "name": "Sommerplausch 2017",
        "abstract": "Dank super schönem Wetter konnte der diesjährige Sommerplausch wie geplant in der wunderschönen Anlage Falter in Oberwil-Lieli durchgeführt werden.",
        "albumKey": "sommerplausch-2017"
      },
      {
        "key": "irland-2017",
        "name": "Nordirland 2017 - der MeterHecht",
        "abstract": "Es war wieder Frühling geworden und somit stand wiederum Nordirland auf dem Programm. Von Neuem verbrachten 4 Würmlitaucher Freunde eine Woche samt Hausboot auf dem Erne in Nordirland.",
        "albumKey": "irland-2017"
      },
      {
        "key": "gv-2017",
        "name": "GV 2017",
        "abstract": "Am 24. Februar 2017 fand die GV im Restaurant Litzi in Jonen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2016",
        "name": "Geselliges Weihnachtsessen der Würmlitaucher 2016",
        "abstract": "Das diesjährige Weihnachtsessen fand nach längerem Unterbruch mal wieder im Central Oberlunkhofen statt. Vollzählig, wie es sich gehört, fand sich Verein, Club oder wie auch immer, zum traditionellen Weihnachtsessen ein.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2016",
        "name": "Vereinsmeisterschaft 2016",
        "abstract": "Die diesjährige Vereinsmeisterschaft auf dem Oberalppass brachte neben viel Fisch mit Andi einen altbekannten und äusserst stolzen Sieger.",
        "albumKey": "fischerausflug-2016"
      },
      {
        "key": "sommerplausch-2016",
        "name": "Sommerplausch 2016",
        "abstract": "Die grosse Würmlitaucherschar (Mitglieder, Anhang und Nachwuchs) traf sich dieses Jahr zum gemütlichen Beisammensein in der Waldhütte Rottenschwil.",
        "albumKey": "sommerplausch-2016"
      },
      {
        "key": "irland-2016",
        "name": "Nordirland 2016",
        "abstract": "Nach ein paar Jahren ohne grosse Fischerreise war es dieses Jahr wieder soweit: Wiederum verbrachten 5 Würmlitaucher-Mitglieder eine Fischerwoche auf dem Erne in Nordirland. Bei gutem aber kaltem Wetter wurden über 60 Hechte sowie mehrere Barsche erbeutet (der Hechtfang entspricht einem neuen Würmlitaucherrekord).",
        "albumKey": "irland-2016"
      },
      {
        "key": "gv-2016",
        "name": "GV 2016",
        "abstract": "Am 22. Februar 2016 fand die GV im Chämi in Oberlunkhofen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2015",
        "name": "Traditionelles Weihnachtsessen der Würmlitaucher 2015",
        "abstract": "Nach dem Motto, mal wieder was Neues, fand das Weihnachtsessen der Würmlitaucher heuer im Restaurant \"Il Giradino\" in Hedingen statt. Vollzähliges wie es sich gehört fand sich Verein, Club oder wie auch immer, zum inzwischen zur Tradition gewordenen Festtagsschmaus ein.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2015",
        "name": "Strahlende Vereinsmeisterschaft 2015",
        "abstract": "Strahlendes Sommerwetter, strahlende Würmlitaucher und ein strahlender Sieger der Vereins-Meisterschaft 2015; ein rundum perfektes Weekend auf dem Oberalppass wird in bester Erinnerung bleiben.",
        "albumKey": "fischerausflug-2015"
      },
      {
        "key": "sommerplausch-2015",
        "name": "Sommerplausch 2015",
        "abstract": "Die grosse Würmlitaucherschar (Mitglieder, Partner und Nachwuchs) traf sich zum gemütlichen Beisammensein beim Vereinsmitglied Thomas. Der Grill lief heiss, das Bier wurde gekühlt und die Jüngsten rannten um die Wette. Ein vollends geglückter Tag, der grossen Anklang fand und inzwischen wichtiger Bestandteil im Vereinsleben der Würmlitaucher geworden ist.",
        "albumKey": "sommerplausch-2015"
      },
      {
        "key": "10-jahr-jubilaeum-in-hamburg-2015",
        "name": "10 Jahr-Jubiläum in Hamburg",
        "abstract": "Zum zehnjährigen Jubiläum verschlug es die Würmlitaucher in die norddeutsche Metropole an der Elbe. Neben viel Kultur, Geschichte und Vergnügen wurde hauptsächlich die Kameradschaft gepflegt.",
        "albumKey": "hamburg-2015"
      },
      {
        "key": "gv-2015",
        "name": "GV 2015",
        "abstract": "Am 23. Februar 2015 fand die GV im Chämi in Oberlunkhofen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2014",
        "name": "Denkwürdiges Weihnachtsessen der Würmlitaucher 2014",
        "abstract": "Das Weihnachtsessen 2014 fand im Rest. Ewigs-Liecht in Werd statt. Fast die gesamte Würmlitaucherschar war anwesend (es fehlte Michael, aber dazu später mehr). Der Anlass fand in gemütlicher Runde, in festlich geschmückter Lokalität, bei feinstem Essen und natürlichen Getränken statt.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2014",
        "name": "Würmlitaucher wiederum am Voralpsee",
        "abstract": "Wie bereits im Vorjahr fischten die Würmlitaucher auch 2014 im Voralpsee bei prächtigem Spätsommerwetter um den Titel \"Champion 2014\".",
        "albumKey": "fischerausflug-2014"
      },
      {
        "key": "sommerplausch-2014",
        "name": "Sommerevent 2014 mit Public Viewing",
        "abstract": "Nachdem der letztjährige Sommerevent so viel Anklang fand, war der Anlass auch dieses Jahr ein wichtiger Programmpunkt im Vereinsleben der Würmlitaucher. Trotz kurzfristiger Programmänderung war das Treffen der Würmlitaucher ein voller Erfolg, der Sieg der Schweizer Nati trug natürlich das Seinige dazu bei.",
        "albumKey": "sommerplausch-2014"
      },
      {
        "key": "gv-2014",
        "name": "GV 2014",
        "abstract": "Am 2. Februar 2014 fand die GV im Litzi in Jonen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2013",
        "name": "Weihnachtsessen der Würmlitaucher 2013",
        "abstract": "Zum diesjährigen Weihnachtsessen traf sich die Würmlitaucherschar zum Essen im Restaurant Casa Hecht zu Rottenschwil ein.",
        "albumKey": undefined
      },
      {
        "key": "irland-2013",
        "name": "Nordirland 2013",
        "abstract": "Dieses Jahr verbrachten einige Würmlitaucher eine Fischerwoche in der Cavan-Seenplatte auf Irland. Bei tollem Wetter wurde eine Menge Barsche erbeutet (der Barschfang entspricht wiederum einem Würmlitaucherrekord). Neben dem Fischen stand dieses Jahr aber auch das Erleben des irischen Lebensstils im Fokus.",
        "albumKey": "irland-2013"
      },
      {
        "key": "vereinsmeisterschaft-2013",
        "name": "Würmlitaucher im Rheintal unterwegs",
        "abstract": "Der diesjährige fischreiche Vereinsevent (neuer Vereinsrekord) führte die Mitglieder bei prächtigem Sommerwetter ins Rheintal. Neben dem gemütlichen Beisammen sein und dem Durchwandern der Taminaschlucht stand das Weekend natürlich ganz im Kampf um den Wanderpokal für den FischerChampion 2013.",
        "albumKey": "fischerausflug-2013"
      },
      {
        "key": "sommerplausch-2013",
        "name": "Sommerplausch 2013",
        "abstract": "Nachdem in den letzten beiden Jahren die Durchführung aus verschiedensten Gründen nicht zu Stande kam, fand anfangs Juni der im Jahre 2010 ins Leben gerufene Sommerplausch wieder statt. Dank der Initiative von Michael und Thomas fand der Anlass trotz schlechter Wetterprognose einerseits grossen Anklang und Dank der top Infrastruktur einen würdigen Rahmen.",
        "albumKey": "sommerplausch-2013"
      },
      {
        "key": "gv-2013",
        "name": "GV 2013",
        "abstract": "Am 13. März 2013 fand die GV im Central in Oberlunkhofen statt.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2012",
        "name": "Weihnachtsessen der Würmlitaucher 2012",
        "abstract": "Wie es sich gehört, fand das Weihnachtsessen der Würmlitaucher ordnungsgemäss in der Adventszeit statt. Der Präsident lud diesmal ins Restaurant Buurehof in Arni ein.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2012",
        "name": "Würmlitaucher auf der Lenzerheide",
        "abstract": "Der Vereinsevent wurde auf dieses Jahr hin ein wenig neu designt und in zwei Teile (gesellschaftlich und Wettkampf) aufgeteilt. Ein paar Regenschauern zum Trotz war der Anlass ein grosser Erfolg und einen neuen Sieger gab es obendrauf.",
        "albumKey": "fischerausflug-2012"
      },
      {
        "key": "irland-2012",
        "name": "Nordirland 2012",
        "abstract": "Auch im Jahr 2012 verbrachten wiederum 5 Würmlitaucher-Mitglieder eine Fischerwoche auf dem Erne in Nordirland. Bei tollem Wetter wurden 40 Hechte und eine Menge Barsche erbeutet (der Barschfang entspricht wiederum einem Würmlitaucherrekord). ",
        "albumKey": "irland-2012"
      },
      {
        "key": "gv-2012",
        "name": "GV 2012",
        "abstract": "Dieses Jahr hielten wir unsere GV im Restaurant Reussbrücke (Ewig’s Liechtli) ab. Im Sähli hatten wir das richtige Ambiente für gute Diskussionen.",
        "albumKey": undefined
      },
      {
        "key": "fachmesse-fischen-jagen-schiessen-2012",
        "name": "Fachmesse \"Fischen Jagen Schiessen 2012\", wir waren da",
        "abstract": "Die diesjährige Messe , mit dem Aargau als Gastkanton, wurde wiederum von Vorstandsmitgliedern der Würmlitaucher besucht, wie schon 2010. Gegenüber damals war die Ausstellung grösser und dürfte deutlich mehr Eintritte verzeichnet haben.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2011",
        "name": "Weihnachtsessen der Würmlitaucher 2011",
        "abstract": "Wie es sich gehört, fand das Weihnachtsessen des Fischerclubs Würmlitaucher ordnungsgemäss in der Adventszeit statt. Dieses Jahr traf man sich auf Einladung des Vorstands zum gemütlichen Zusammensein im Restaurant Huserhof in Unterlunkhofen.",
        "albumKey": undefined
      },
      {
        "key": "fischertag-oberalpsee-2011",
        "name": "Erfolgreicher Fischertag auf dem Oberalppass",
        "abstract": "4 Fischerfreunde der Würmlitaucher verbrachten einen äusserst ergiebigen Fischertag auf dem Oberalppass. Innert 3 Stunden waren 23 Forellen erbeutet und das Wetter, trotz mässiger Prognosen, war auch besser als gedacht.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2011",
        "name": "Fischerausflug 2011",
        "abstract": "Der Vereinswettkampf 2011 wurde erstmals auf der Engstlenalp durchgeführt und war begleitet von viel Regen. Trotz der Nässe war das Weekend geprägt von Spass und Forellen und zudem gab es auch einen Wettkampfsieger.",
        "albumKey": "fischerausflug-2011"
      },
      {
        "key": "irland-2011",
        "name": "Hausbootferien Nordirland 2011",
        "abstract": "Für eine Woche fuhren auch 2011 wiederum 5 Würmlitaucher-Mitglieder an den Erne in Nordirland um ein paar Angeltage vom Hausboot aus zu geniessen. Bei prächtigem Wetter und super Stimmung wurden 27 Hechte und knapp 80 Barsche erbeutet (der Barschfang entspricht einen neuen Würmlitaucherrekord).",
        "albumKey": "irland-2011"
      },
      {
        "key": "tomatenhausbau-bei-stef",
        "name": "Wuermlitaucher goes Tomatenhaus",
        "abstract": "Im Sinne eines gesellschaftlichen Anlasses trafen sich die Wuemlitaucher zur gemeinsamen Errichtung eines Tomaten-/Gewächshauses bei Stef ein. Bei Bier, Wurst und Brot entstand unter guter Laune und prächtigem Wetter ein tolles selbstgebautes Gewächshaus.",
        "albumKey": "tomatenhausbau-bei-stef"
      },
      {
        "key": "gv-2011",
        "name": "GV 2011",
        "abstract": "Dieses Jahr hielten wir unsere GV im Restaurant Weingarten in Affoltern am Albis ab.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2010",
        "name": "Weihnachtsessen der Würmlitaucher 2010",
        "abstract": "Das Weihnachtsessen der Würmlitaucher 2010 fand wieder mal pünktlich, nämlich am 22. Dezember statt. Für den Jahresausklang 2010 lud der Vorstand die Vereinsmitglieder zur gemeinsamen Runde ins Stars and Stripes auf dem Mutschellen ein.",
        "albumKey": undefined
      },
      {
        "key": "5-jahr-jubilaeum-in-duesseldorf-2010",
        "name": "5-Jahr-Jubiläum Düsseldorf 2010",
        "abstract": "Die erste Jubiläumsreise, das Fünfjährige, führte die Vereinsgenossen an den Rhein nach Düsseldorf. Drei unvergessliche Tage in Altstadt, am Rhein, auf dem Turm und im Schlosspark wurden mit dem einen oder anderen Schluck Alt begossen. Das Jubiläum wurde bei angenehmen Temperaturen und nur 2 kurzen Regengüssen durchgeführt.",
        "albumKey": "duesseldorf-2010"
      },
      {
        "key": "vereinsmeisterschaft-2010",
        "name": "Fischerausflug 2010",
        "abstract": "Der denkwürdige Event aus dem Jahre 2009 mit 18 Forellen konnte zwar nicht übertroffen werden, dafür waren die einzelnen Fangerfolge in den Längen beachtlich und auch das Wetter war wiederum auf der Seite der Würmlitaucher.",
        "albumKey": "fischerausflug-2010"
      },
      {
        "key": "gelungene-oberalp-premiere-2010",
        "name": "Gelungene Oberalp-Premiere",
        "abstract": "Kurzentschlossen machten sich die Würmlitaucher Christoph, Stef sowie Marco Kramer an einem Montag auf zum Oberalppass. Die Entscheidung für diesen Bergsee fiel aufgrund besserer Wetteraussichten als auf der Melchsee-Frutt.",
        "albumKey": undefined
      },
      {
        "key": "sommerplausch-2010",
        "name": "Sommerevent 2010",
        "abstract": "Der anlässlich der letzten GV ins Leben gerufene Sommerevent wurde nun ein erstes Mal durchgeführt. Bei heissen Temperaturen und bester Organisation gibt es nur ein Fazit: ein rundum geglückter Anlass.",
        "albumKey": undefined
      },
      {
        "key": "irland-2010",
        "name": "Hausbootferien Nordirland 2010",
        "abstract": "Für eine Woche fuhren 4 Würmlitaucher-Mitglieder an den Erne in Nordirland um ein paar Angeltage vom Hausboot aus zu geniessen. Bei prächtigem Wetter und super Stimmung wurden über 30 Hechte und ebenso viele Barsche erbeutet. Wie schon 2008 war es Christoph vorbehalten, eine wunderschöne Irland-Forelle zu landen.",
        "albumKey": "irland-2010"
      },
      {
        "key": "hai-von-stef",
        "name": "Hai",
        "abstract": "Stef und Anita haben auf ihrer Reise in der USA einen Halt gemacht um einmal einen richtig grossen Fisch an Land zu ziehen.",
        "albumKey": "hai-von-stef"
      },
      {
        "key": "gv-2010",
        "name": "GV 2010",
        "abstract": "Wie bereits vor der GV angekündigt, müssen wir ein paar Entscheide fällen.",
        "albumKey": undefined
      },
      {
        "key": "fachmesse-fischen-jagen-schiessen-2010",
        "name": "Besuch Fachmesse Fischen Jagen Schiessen 2010: bea expo",
        "abstract": "Vertreter des Vorstands der Würmlitaucher liessen es sich nicht nehmen an der Fachmesse für Fischen und Jagen in Bern teilzunehmen. Die Fachmesse war erstaunlich gut besucht. Die eine Etage des bea expo Komplexes widmete sich der Jagd, die andere der Fischerei.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2009",
        "name": "Weihnachtsessen 2009",
        "abstract": "Das Weihnachtsessen der Würmlitaucher 2009 fand aus terminlichen Gründen anfangs Februar 2010 statt. Nachdem der Event letztes Jahr ein voller Erfolg war, lud der Vorstand für den Ausklang des Jahres 2009 nach Lieli ins Restaurant Hirschen ein.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2009",
        "name": "Ein Weekend für die Geschichte",
        "abstract": "Der Vereinswettkampf 2009 geht in Geschichte der Würmlitaucher ein. Alles bis dahin geltende wurde pulverisiert. Angefangen beim prächtigen Sommerwetter, über die Anzahl gefangener Fische bis hin zur prächtigen Siegerforelle. Ein rundum geglückter Anlass, der Lust auf mehr macht.",
        "albumKey": "fischerausflug-2009"
      },
      {
        "key": "schweden-2009",
        "name": "Schwedentörn 2009",
        "abstract": "Hubi, Ralph, Christoph und Stef verbrachten im Mai 2009 eine Woche in Südschweden. Das gemietete Ferienhaus erwies sich als absoluter Volltreffer. Bei völliger Alleinlage am grossen See Nabben (ca 12 km2) und herrlichem Wetter wurden über 20 Hechte verhaftet.",
        "albumKey": "schweden-2009"
      },
      {
        "key": "gv-2009",
        "name": "GV 2009",
        "abstract": "Die zweite GV wiedereinmal top besucht. Der Verein war komplett anwesend und wir konnten einige aktuelle Themen besprechen.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2008",
        "name": "Weihnachtsessen 2008",
        "abstract": "Lange her, dass sich auf der Homepage etwas getan hat. Während der Volleyball-Saison ist der Verein nicht ganz so aktiv. Wir hatten auf jedenfall ein schönes Weichnachtsesse und es war schön wieder einmal alle Mitglieder an einem Tisch zu haben.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2008",
        "name": "Vereinsevent 2008 auf Melchsee-Frutt",
        "abstract": "Bei nicht gerade optimalem Wetter fand am Wochenende vom 12.+13. Juli 2008 der Vereinsevent 2008 auf der Melchsee-Frutt statt. Unterkunft, Verpflegung und der grosse Spassfaktor führten aber zu einem unvergesslichen Erlebnis. Mit 4 gefangenen Forellen ging der Wanderpokal dieses Jahr an Thomas A.",
        "albumKey": "fischerausflug-2008"
      },
      {
        "key": "irland-2008",
        "name": "10 Tage Irland 2008, mit Hausboot auf dem Shannon",
        "abstract": "Im Mai 2008 verbrachten 4 Jungs 10 traumhafte Tage auf dem Shannon. Als Unterkunft diente ein geräumiges Hausboot von Waveline Cruisiers. Die Reise führte vom Lough Ree zuerst südlich nach Athlone und danach nordwärts über Carrick on Shannon bis in die beiden Shannon Seen Lough Key und Lough Allen. Nach dem Motto von Catch and Release wurden gute 44 Hechte gestellt, dazu eine wunderschöne BrownTrout mit 52cm, sowie Brachsmen und diverse Barsche.",
        "albumKey": "irland-2008"
      },
      {
        "key": "gv-2008",
        "name": "GV 2008",
        "abstract": "Die erste GV war ein voller Erfolg. Die Mitglieder des Vereines waren komplett anwesend und bei einem feinen Stück Fleisch war dies ein sehr gelungener Abend.",
        "albumKey": undefined
      },
      {
        "key": "weihnachtsessen-2007",
        "name": "Weihnachtsessen 2007",
        "abstract": "Zum Weihnachtsessen 2007 trafen sich die Mitglieder der Würmlitaucher im Restaurant Litzi in Jonen.",
        "albumKey": undefined
      },
      {
        "key": "fischerausflug-waegitalersee-27-september-2007",
        "name": "3 Wägi-Forellen am 27. September 2007",
        "abstract": "Zum definitiven Saisonabschluss machten sich Chrigel und Stef zusammen mit dem Gast Hubi nochmals auf Richtung Wägital.",
        "albumKey": undefined
      },
      {
        "key": "fischerausflug-waegitalersee-15-september-2007",
        "name": "5 Wägi-Forellen zum Saisonabschluss",
        "abstract": "Ausgerüstet mit topaktuellen Schlepputensilien, die meisten in rot gehalten, wurde der Samstag in Angriff genommen. Um 08.45 h traf man sich beim Fischerladen in Innerthal und bezog die beiden Motorschiffe mit Verdeck.",
        "albumKey": "fischerausflug-waegitalersee-15-september-2007"
      },
      {
        "key": "vereinsmeisterschaft-2007",
        "name": "Präsi holt sich die Jahresmeisterschaft",
        "abstract": "Die Jahresmeisterschaft 2007 geht an den Präsidenten Th. Tardy. Ein zünftiges Egli ging an seinen Haken an den Ufern des Vierwaldstättersee’s zu Weggis.",
        "albumKey": "fischerausflug-2007"
      },
      {
        "key": "erste-offizielle-sitzung-2007",
        "name": "Erste offizielle Sitzung",
        "abstract": "An der ersten offiziellen Sitzung unseres Fereins werden die folgenden Punkte diskutiert.",
        "albumKey": undefined
      },
      {
        "key": "wahl-des-fischerrates-2007",
        "name": "Wahl des Fischerrates",
        "abstract": "Die Kandidaten für den Fischerrat werden von den Anwesenden (Michi, Andi, Ralf, Stef, Thomas) einstimmig angenommen.",
        "albumKey": undefined
      },
      {
        "key": "gruendung-des-vereins-2007",
        "name": "Gründung des Vereins",
        "abstract": "Im Rahmen der Organisation des zweiten Fischerausfluges werden wir vom Anklang eines solchen Events so überrascht, dass wir uns entschiessen, dies auch in Zukunft durchführen zu wollen.",
        "albumKey": undefined
      },
      {
        "key": "vereinsmeisterschaft-2006",
        "name": "Erster Fischerevent: 13.+14. Mai 2006 am Sihlsee",
        "abstract": "Samstagmorgens um 10.00h besammelte sich die muntere Fischerschar pünktlich bei der Sporthalle zur Abfahrt Richtung Innerschweiz.",
        "albumKey": "fischerausflug-2006"
      }
    ]
  }
}
