import {Injectable} from '@angular/core'
import {HomeDataModel, HomeDataType} from '../models/data.model'

@Injectable({
  providedIn: 'root'
})
export class HomeDataService {

  getData(): Array<HomeDataModel> {
    return [
      {
        "type": HomeDataType.ARTICLE,
        "key": "vereinsmeisterschaft-2024"
      },
      {
        "type": HomeDataType.ARTICLE,
        "key": "gv-2024"
      },
      {
        "type": HomeDataType.ARTICLE,
        "key": "vereinsmeisterschaft-2023"
      },
      {
        "type": HomeDataType.ALBUM,
        "key": "vereinsreise-2023"
      },
      {
        "type": HomeDataType.ALBUM,
        "key": "irland-2023"
      },
      {
        "type": HomeDataType.ALBUM,
        "key": "vereinsreise-2022"
      }
    ]
  }
}
