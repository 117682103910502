import {Component, ViewChild} from '@angular/core'
import {BreakpointObserver} from '@angular/cdk/layout'
import {Router} from '@angular/router'
import {NavigationService} from '../../services/navigation.service'
import {MatButton} from '@angular/material/button'
import {MatSidenav} from '@angular/material/sidenav'

@Component({
  selector: 'wt-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  readonly NAVIGATION_ITEMS = NavigationService.NAVIGATION_ITEMS

  isSmallDevice = false

  @ViewChild('sidenavToggleButton', {static: true})
  private readonly sidenavToggleButton!: MatButton

  @ViewChild('drawer', {static: true})
  private readonly sidenavDrawer!: MatSidenav

  constructor(private readonly router: Router,
              breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe(
      '(max-width: 959px)'
    ).subscribe(result => {
      if (this.isSmallDevice && !result.matches) {
        this.sidenavDrawer.close().then(() => {
          // ignore
        })
      }
      this.isSmallDevice = result.matches
    })
  }

  blurSidenavToggleButton(): void {
    this.sidenavToggleButton._elementRef.nativeElement.blur()
  }
}
