import {Component, EventEmitter, Input, Output} from '@angular/core'
import {ImageClickEvent, ImageGridItem} from '../../../core/models/data.model'

@Component({
  selector: 'wt-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss']
})
export class ImageGridComponent<T extends ImageGridItem> {

  @Input()
  public items: T[] = []

  @Input()
  public imageUrlFunction: (item: T) => string = (item: T) => {
    return item.src
  }

  @Input()
  public imageNameFunction: (item: T) => string = () => {
    return ''
  }

  @Input()
  public isImageStackFunction: (item: T) => boolean = () => {
    return false
  }

  @Input()
  public showImageNameInGrid = false

  @Output()
  private itemClicked: EventEmitter<ImageClickEvent<T>> = new EventEmitter()

  handleItemClick(item: T, index: number): void {
    this.itemClicked.emit(new ImageClickEvent(item, index))
  }
}
