import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'
import {CommonModule} from '@angular/common'
import {MatButtonModule} from '@angular/material/button'
import {MatRippleModule} from '@angular/material/core'
import {LightboxComponent} from './components/lightbox/lightbox.component'
import {MatIconModule} from '@angular/material/icon'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {ImageGridComponent} from './components/image-grid/image-grid.component'
import {OverlayModule} from '@angular/cdk/overlay'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatSidenavModule} from '@angular/material/sidenav'
import {MatSlideToggleModule} from '@angular/material/slide-toggle'
import {MatListModule} from '@angular/material/list'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    OverlayModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule
  ],
  declarations: [
    ImageGridComponent,
    LightboxComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule,
    ImageGridComponent,
    LightboxComponent
  ]
})
export class SharedModule {
}
