import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  static isUndefinedOrNullOrEmptyString<T>(value?: T): boolean {
    return value === undefined || value === null || String(value).trim().length === 0 || (value instanceof Array && value.length === 0)
  }

  static isNotUndefinedOrNullOrEmptyString<T>(value?: T): boolean {
    return !UtilService.isUndefinedOrNullOrEmptyString(value)
  }

  static ensureDefined<T>(value?: T): T {
    if (value !== undefined) {
      return value
    } else {
      throw new Error('Illegal state: provided value is expected to be defined')
    }
  }
}
