import {Component, EventEmitter, Output} from '@angular/core'
import {NavigationService} from '../../services/navigation.service'

@Component({
  selector: 'wt-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent {

  readonly NAVIGATION_ITEMS = NavigationService.NAVIGATION_ITEMS

  @Output()
  private readonly navigationItemClicked = new EventEmitter()

  handleClick(): void {
    this.navigationItemClicked.emit()
  }
}
