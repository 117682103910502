import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {MainLayoutComponent} from './core/components/main-layout/main-layout.component'
import {ARTICLES_PATH, CLUB_PATH, EVENTS_PATH, GALLERY_PATH, HOME_PATH} from './shared/constants/route.constants'
import {HomeComponent} from './core/components/home/home.component'

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: HOME_PATH
      },
      {
        path: HOME_PATH,
        component: HomeComponent,
        pathMatch: 'full',
        data: {
          pageTitle: HomeComponent.PAGE_TITLE
        }
      },

      // Lazy loaded modules
      {
        path: CLUB_PATH,
        loadChildren: () => import('./club/club.module').then(m => m.ClubModule)
      },
      {
        path: EVENTS_PATH,
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: ARTICLES_PATH,
        loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
      },
      {
        path: GALLERY_PATH,
        loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: HOME_PATH
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
