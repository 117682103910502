<div class="hero-image">
    <img [src]="imgSrc" alt="home image">
</div>

<div class="section">
    <div class="container">
        <div class="section-title">
            <h2>Willkommen</h2>
        </div>
        <div>
            Willkommen auf der Homepage des Vereins <span class="prevent-wrap">Lunki’s Würmlitaucher.</span>
            <br>
            Hier finden Sie Berichte und Fotos von unseren Anlässen.
        </div>
        <div>
            <wt-image-grid [items]="homeItems" [imageUrlFunction]="imageUrlFunction" [imageNameFunction]="imageNameFunction" [isImageStackFunction]="isImageStackFunction"
                           [showImageNameInGrid]="true" (itemClicked)="navigateToAlbum($event)"></wt-image-grid>
        </div>
    </div>
</div>

