export const HOME_NAME = 'Willkommen'
export const HOME_PATH = 'home'
export const CLUB_NAME = 'Verein'
export const CLUB_PATH = 'club'
export const EVENTS_NAME = 'Anlässe'
export const EVENTS_PATH = 'events'
export const ARTICLES_NAME = 'Berichte'
export const ARTICLES_PATH = 'articles'
export const GALLERY_NAME = 'Galerie'
export const GALLERY_PATH = 'gallery'
export const ALBUM_NAME = 'Album'
export const ALBUM_PATH = 'album'

export const ARTICLE_VEREINSMEISTERSCHAFT_2024_PATH = 'vereinsmeisterschaft-2024'
export const ARTICLE_GV_2024_PATH = 'gv-2024'
export const ARTICLE_VEREINSMEISTERSCHAFT_2023_PATH = 'vereinsmeisterschaft-2023'
export const ARTICLE_GV_2023_PATH = 'gv-2023'
export const ARTICLE_VEREINSMEISTERSCHAFT_2022_PATH = 'vereinsmeisterschaft-2022'
export const ARTICLE_GV_2022_PATH = 'gv-2022'
export const ARTICLE_VEREINSREISE_2021_PATH = 'vereinsreise-2021'
export const ARTICLE_VEREINSMEISTERSCHAFT_2021_PATH = 'vereinsmeisterschaft-2021'
export const ARTICLE_15_JAHR_JUBILAEUMSREISE_2020_PATH = '15-jahr-jubilaeumsreise-2020'
export const ARTICLE_VEREINSMEISTERSCHAFT_2020_PATH = 'vereinsmeisterschaft-2020'
export const ARTICLE_GV_2020_PATH = 'gv-2020'
export const ARTICLE_WEIHNACHTSESSEN_2019_PATH = 'weihnachtsessen-2019'
export const ARTICLE_VEREINSMEISTERSCHAFT_2019_PATH = 'vereinsmeisterschaft-2019'
export const ARTICLE_SOMMERPLAUSCH_2019_PATH = 'sommerplausch-2019'
export const ARTICLE_IRLAND_2019_PATH = 'irland-2019'
export const ARTICLE_GV_2019_PATH = 'gv-2019'
export const ARTICLE_WEIHNACHTSESSEN_2018_PATH = 'weihnachtsessen-2018'
export const ARTICLE_VEREINSMEISTERSCHAFT_2018_PATH = 'vereinsmeisterschaft-2018'
export const ARTICLE_SOMMERPLAUSCH_2018_PATH = 'sommerplausch-2018'
export const ARTICLE_GV_2018_PATH = 'gv-2018'
export const ARTICLE_WEIHNACHTSESSEN_2017_PATH = 'weihnachtsessen-2017'
export const ARTICLE_VEREINSMEISTERSCHAFT_2017_PATH = 'vereinsmeisterschaft-2017'
export const ARTICLE_SOMMERPLAUSCH_2017_PATH = 'sommerplausch-2017'
export const ARTICLE_IRLAND_2017_PATH = 'irland-2017'
export const ARTICLE_GV_2017_PATH = 'gv-2017'
export const ARTICLE_WEIHNACHTSESSEN_2016_PATH = 'weihnachtsessen-2016'
export const ARTICLE_VEREINSMEISTERSCHAFT_2016_PATH = 'vereinsmeisterschaft-2016'
export const ARTICLE_SOMMERPLAUSCH_2016_PATH = 'sommerplausch-2016'
export const ARTICLE_IRLAND_2016_PATH = 'irland-2016'
export const ARTICLE_GV_2016_PATH = 'gv-2016'
export const ARTICLE_WEIHNACHTSESSEN_2015_PATH = 'weihnachtsessen-2015'
export const ARTICLE_VEREINSMEISTERSCHAFT_2015_PATH = 'vereinsmeisterschaft-2015'
export const ARTICLE_SOMMERPLAUSCH_2015_PATH = 'sommerplausch-2015'
export const ARTICLE_10_JAHR_JUBILAEUM_IN_HAMBURG_2015_PATH = '10-jahr-jubilaeum-in-hamburg-2015'
export const ARTICLE_GV_2015_PATH = 'gv-2015'
export const ARTICLE_WEIHNACHTSESSEN_2014_PATH = 'weihnachtsessen-2014'
export const ARTICLE_VEREINSMEISTERSCHAFT_2014_PATH = 'vereinsmeisterschaft-2014'
export const ARTICLE_SOMMERPLAUSCH_2014_PATH = 'sommerplausch-2014'
export const ARTICLE_GV_2014_PATH = 'gv-2014'
export const ARTICLE_WEIHNACHTSESSEN_2013_PATH = 'weihnachtsessen-2013'
export const ARTICLE_IRLAND_2013_PATH = 'irland-2013'
export const ARTICLE_VEREINSMEISTERSCHAFT_2013_PATH = 'vereinsmeisterschaft-2013'
export const ARTICLE_SOMMERPLAUSCH_2013_PATH = 'sommerplausch-2013'
export const ARTICLE_GV_2013_PATH = 'gv-2013'
export const ARTICLE_WEIHNACHTSESSEN_2012_PATH = 'weihnachtsessen-2012'
export const ARTICLE_VEREINSMEISTERSCHAFT_2012_PATH = 'vereinsmeisterschaft-2012'
export const ARTICLE_IRLAND_2012_PATH = 'irland-2012'
export const ARTICLE_GV_2012_PATH = 'gv-2012'
export const ARTICLE_FACHMESSE_FISCHEN_JAGEN_SCHIESSEN_2012 = 'fachmesse-fischen-jagen-schiessen-2012'
export const ARTICLE_WEIHNACHTSESSEN_2011_PATH = 'weihnachtsessen-2011'
export const ARTICLE_FISCHERTAG_OBERALPSEE_2011 = 'fischertag-oberalpsee-2011'
export const ARTICLE_VEREINSMEISTERSCHAFT_2011_PATH = 'vereinsmeisterschaft-2011'
export const ARTICLE_IRLAND_2011_PATH = 'irland-2011'
export const ARTICLE_TOMATENHAUSBAU_BEI_STEF_PATH = 'tomatenhausbau-bei-stef'
export const ARTICLE_GV_2011_PATH = 'gv-2011'
export const ARTICLE_WEIHNACHTSESSEN_2010_PATH = 'weihnachtsessen-2010'
export const ARTICLE_5_JAHR_JUBILAEUM_IN_DUESSELDORF_2010_PATH = '5-jahr-jubilaeum-in-duesseldorf-2010'
export const ARTICLE_VEREINSMEISTERSCHAFT_2010_PATH = 'vereinsmeisterschaft-2010'
export const ARTICLE_GELUNGENE_OBERALP_PREMIERE_2010_PATH = 'gelungene-oberalp-premiere-2010'
export const ARTICLE_SOMMERPLAUSCH_2010_PATH = 'sommerplausch-2010'
export const ARTICLE_IRLAND_2010_PATH = 'irland-2010'
export const ARTICLE_HAI_VON_STEF_PATH = 'hai-von-stef'
export const ARTICLE_GV_2010_PATH = 'gv-2010'
export const ARTICLE_FACHMESSE_FISCHEN_JAGEN_SCHIESSEN_2010 = 'fachmesse-fischen-jagen-schiessen-2010'
export const ARTICLE_WEIHNACHTSESSEN_2009_PATH = 'weihnachtsessen-2009'
export const ARTICLE_VEREINSMEISTERSCHAFT_2009_PATH = 'vereinsmeisterschaft-2009'
export const ARTICLE_SCHWEDEN_2009_PATH = 'schweden-2009'
export const ARTICLE_GV_2009_PATH = 'gv-2009'
export const ARTICLE_WEIHNACHTSESSEN_2008_PATH = 'weihnachtsessen-2008'
export const ARTICLE_VEREINSMEISTERSCHAFT_2008_PATH = 'vereinsmeisterschaft-2008'
export const ARTICLE_IRLAND_2008_PATH = 'irland-2008'
export const ARTICLE_GV_2008_PATH = 'gv-2008'
export const ARTICLE_WEIHNACHTSESSEN_2007_PATH = 'weihnachtsessen-2007'
export const ARTICLE_FISCHERAUSFLUG_WAEGITALERSEE_27_SEPTEMBER_2007_PATH = 'fischerausflug-waegitalersee-27-september-2007'
export const ARTICLE_FISCHERAUSFLUG_WAEGITALERSEE_15_SEPTEMBER_2007_PATH = 'fischerausflug-waegitalersee-15-september-2007'
export const ARTICLE_VEREINSMEISTERSCHAFT_2007_PATH = 'vereinsmeisterschaft-2007'
export const ARTICLE_ERSTE_OFFIZIELLE_SITZUNG_2007_PATH = 'erste-offizielle-sitzung-2007'
export const ARTICLE_WAHL_DES_FISCHERRATES_2007_PATH = 'wahl-des-fischerrates-2007'
export const ARTICLE_GRUENDUNG_DES_VEREINS_2007_PATH = 'gruendung-des-vereins-2007'
export const ARTICLE_VEREINSMEISTERSCHAFT_2006_PATH = 'vereinsmeisterschaft-2006'
