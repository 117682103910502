export const SERVER_API_URL = ''

export const TITLE_POSTFIX = 'Lunki\'s Würmlitaucher'

export const LOCALE = 'de-CH'

export const ALBUMS_URL_PART = 'photos'
export const THUMBNAILS_URL_PART = 'thumbnails'

export const ALBUM_KEY_PARAM = 'albumKey'
