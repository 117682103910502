import {LOCALE_ID, NgModule} from '@angular/core'
import {LOCALE} from '../app.constants'
import locale from '@angular/common/locales/de-CH'
import {registerLocaleData} from '@angular/common'
import {SharedModule} from '../shared/shared.module'
import {MainLayoutComponent} from './components/main-layout/main-layout.component'
import {HomeComponent} from './components/home/home.component'
import {SideNavigationComponent} from './components/side-navigation/side-navigation.component'

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    MainLayoutComponent,
    SideNavigationComponent,
    HomeComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: LOCALE
    }
  ]
})
export class CoreModule {
  constructor() {
    registerLocaleData(locale)
  }
}
