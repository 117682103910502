import {Component, HostListener, Inject} from '@angular/core'
import {OverlayRef} from '@angular/cdk/overlay'
import saveFile from 'save-as-file'
import {LIGHTBOX_DATA, LightboxData, LightboxItem} from '../../models/lightbox.model'

@Component({
  selector: 'wt-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent {

  currentItem: LightboxItem
  loading = true
  private currentIndex: number

  constructor(private overlayRef: OverlayRef,
              @Inject(LIGHTBOX_DATA) private overlayData: LightboxData) {
    this.currentIndex = overlayData.initialIndex
    this.currentItem = overlayData.items[this.currentIndex]
  }

  // noinspection JSUnusedLocalSymbols
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownEscape(): void {
    this.closeOverlay()
  }

  closeOverlay(): void {
    this.overlayRef.dispose()
  }

  public hasMoreThanOneImage(): boolean {
    return this.overlayData.items.length > 1
  }

  // noinspection JSUnusedLocalSymbols
  @HostListener('document:keydown.arrowLeft', ['$event'])
  onKeydownLeft(): void {
    this.showPreviousImage()
  }

  showPreviousImage(): void {
    if (this.hasMoreThanOneImage()) {
      this.loading = true
      this.currentIndex = this.currentIndex - 1 < 0 ? this.overlayData.items.length - 1 : this.currentIndex - 1
      this.currentItem = this.overlayData.items[this.currentIndex]
    }
  }

  // noinspection JSUnusedLocalSymbols
  @HostListener('document:keydown.arrowRight', ['$event'])
  onKeydownRight(): void {
    this.showNextImage()
  }

  showNextImage(): void {
    if (this.hasMoreThanOneImage()) {
      this.loading = true
      this.currentIndex = (this.currentIndex + 1) % this.overlayData.items.length
      this.currentItem = this.overlayData.items[this.currentIndex]
    }
  }

  handleImageLoaded(): void {
    this.loading = false
  }

  downloadImage(): void {
    const indexOfLastSlash = this.currentItem.src.lastIndexOf('/')
    const fileName = this.currentItem.src.substring(indexOfLastSlash + 1)
    saveFile(this.currentItem.src, fileName)
  }
}
