<div class="toolbar">
    <button mat-icon-button (click)="downloadImage()">
        <mat-icon>file_download</mat-icon>
    </button>
    <button mat-icon-button (click)="closeOverlay()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<button mat-icon-button *ngIf="hasMoreThanOneImage()" (click)="showPreviousImage()">
    <mat-icon>chevron_left</mat-icon>
</button>
<div class="image-container">
    <div class="loading-spinner-container" *ngIf="loading">
        <mat-spinner diameter="60"></mat-spinner>
    </div>
    <div class="navigable-image">
        <img [src]="currentItem.src" (load)="handleImageLoaded()" [hidden]="loading" alt="image">
        <div *ngIf="hasMoreThanOneImage()" class="clickable navigation previous" (click)="showPreviousImage()"></div>
        <div *ngIf="hasMoreThanOneImage()" class="clickable navigation next" (click)="showNextImage()"></div>
    </div>
</div>
<button mat-icon-button *ngIf="hasMoreThanOneImage()" (click)="showNextImage()">
    <mat-icon>chevron_right</mat-icon>
</button>
