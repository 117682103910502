import {Component, OnInit} from '@angular/core'
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router'
import {Title} from '@angular/platform-browser'
import {getPageTitle} from './core/util/page-title.util'
import {TITLE_POSTFIX} from './app.constants'
import {filter, map} from 'rxjs/operators'

@Component({
  selector: 'wt-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private readonly router: Router,
              private readonly titleService: Title) {
  }

  ngOnInit(): void {
    this.updatePageTitleOnRouteChanges()
    this.scrollToTopAfterRouteChanges()
  }

  private updatePageTitleOnRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.titleService.setTitle(AppComponent.getPageTitle(this.router.routerState.snapshot.root))
      }
    })
  }

  private static getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title = getPageTitle(routeSnapshot)

    if (!title) {
      title = TITLE_POSTFIX
    } else if (title && title.indexOf(TITLE_POSTFIX) < 0) {
      title += ` - ${TITLE_POSTFIX}`
    }
    return title
  }

  private scrollToTopAfterRouteChanges() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => {
        const sidenavContentElement = document.querySelector(
          '.mat-sidenav-content',
        )

        setTimeout(() => {
          // Microsoft Edge does not support scrollTo on div
          if (!sidenavContentElement || typeof sidenavContentElement.scrollTo !== 'function') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sidenavContentElement!.scrollTop = 0
          } else {
            sidenavContentElement.scrollTo({
              left: 0,
              top: 0
            })
          }
        }, 0)
      })
    ).subscribe()
  }
}
