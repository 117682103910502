import {Component} from '@angular/core'
import {ALBUM_PATH, ARTICLES_PATH, GALLERY_PATH, HOME_NAME} from '../../../shared/constants/route.constants'
import {Router} from '@angular/router'
import {ALBUMS_URL_PART, THUMBNAILS_URL_PART} from '../../../app.constants'
import {HomeDataService} from '../../services/home-data.service'
import {GalleryItem, HomeDataModel, HomeDataType, HomeItem, ImageClickEvent} from '../../models/data.model'
import {ArticleDataService} from '../../services/article-data.service'
import {AlbumDataService} from '../../services/album-data.service'
import {UtilService} from '../../services/util.service'

@Component({
  selector: 'wt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  static readonly PAGE_TITLE = HOME_NAME

  readonly imgSrc: string = ''

  homeItems: HomeItem[] = []

  imageUrlFunction = (galleryItem: GalleryItem): string => galleryItem.thumb
  imageNameFunction = (galleryItem: GalleryItem): string => galleryItem.name
  isImageStackFunction = (): boolean => false

  constructor(
    private readonly homeDataService: HomeDataService,
    private readonly articleDataService: ArticleDataService,
    private readonly albumDataService: AlbumDataService,
    private readonly router: Router
  ) {
    this.imgSrc = '/assets/home-hero.png'

    this.homeItems = this.homeDataService.getData().map((homeData: HomeDataModel) => {
      const type = homeData.type
      const key = homeData.key
      let albumKey: string
      let previewImg: string
      let name: string

      if (homeData.type === HomeDataType.ARTICLE) {
        const articleData = articleDataService.getDataByKey(homeData.key)
        albumKey = UtilService.ensureDefined(articleData.albumKey)
        const albumData = albumDataService.getDataByKey(albumKey)
        previewImg = albumData.previewImg
        name = articleData?.name
      } else {
        albumKey = homeData.key
        const albumData = albumDataService.getDataByKey(albumKey)
        previewImg = albumData.previewImg
        name = albumData?.name
      }

      return new HomeItem(
        type,
        key,
        `${ALBUMS_URL_PART}/${albumKey}/${previewImg}`,
        `${ALBUMS_URL_PART}/${albumKey}/${THUMBNAILS_URL_PART}/${previewImg}`,
        name
      )
    })
  }

  navigateToAlbum(imageClickEvent: ImageClickEvent<HomeItem>): void {
    if (imageClickEvent.item.type === HomeDataType.ARTICLE) {
      this.router.navigate(['/', ARTICLES_PATH, imageClickEvent.item.key]).then(() => {
        // ignore
      })
    } else {
      this.router.navigate(['/', GALLERY_PATH, ALBUM_PATH, imageClickEvent.item.key]).then(() => {
        // ignore
      })
    }
  }
}
