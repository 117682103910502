<mat-toolbar class="header">
    <div class="container">
        <div class="brand clickable" routerLink="/" (click)="drawer.close()">
            <img class="logo" routerLink="/" (click)="drawer.close()" src="../../../../assets/logo-no-text.png" alt="small-logo">
            <div class="wt-page-title">Lunki's Würmlitaucher</div>
        </div>
        <div class="spacer"></div>
        <div class="header-navigation">
            <a *ngFor="let navigationItem of NAVIGATION_ITEMS" class="navigation-link" routerLinkActive="active"
               [routerLink]="['/', navigationItem.target]">{{navigationItem.name}}</a>
        </div>
        <button class="sidenav-toggle-button" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" #sidenavToggleButton>
            <mat-icon aria-label="Side nav toggle icon">{{ drawer.opened ? 'close' : 'menu' }}</mat-icon>
        </button>
    </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer
                 position="end"
                 class="sidenav">
        <wt-side-navigation (navigationItemClicked)="drawer.toggle(); blurSidenavToggleButton()"></wt-side-navigation>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
