<div class="navigation-block">
    <div class="heading">
        <div class="icon">
            <mat-icon>explore</mat-icon>
        </div>
        <div class="title">Navigation</div>
    </div>
    <mat-nav-list disableRipple="true" class="main-navigation">
        <a *ngFor="let navigationItem of NAVIGATION_ITEMS" mat-list-item routerLinkActive="active" (click)="handleClick()"
           [routerLink]="['/', navigationItem.target]">{{navigationItem.name}}</a>
    </mat-nav-list>
</div>
